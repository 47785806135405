import React, { useEffect, useState, useCallback} from 'react';
import { MapContainer, TileLayer, GeoJSON, useMapEvents, Marker } from 'react-leaflet';
import * as turf from '@turf/turf';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ZoneRegulations from './ZoneRegulations';
import Tabs from './Tabs';

function MapComponent() {
  const [zoneData, setZoneData] = useState(null);
  const [userZone, setUserZone] = useState(null);
  const [pinLocation, setPinLocation] = useState(null);
  const [regulations, setRegulations] = useState(null);
  const [activeTab, setActiveTab] = useState('zoneWide'); // Default tab is "zoneWide"

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/Fisheries_Management_Zone.geojson`)
      .then((response) => response.json())
      .then((data) => setZoneData(data))
      .catch((error) => console.error('Error loading GeoJSON:', error));
  }, []);

  const loadZoneData = useCallback((zoneId) => {
    let fileName = '';
    switch (activeTab) {
      case 'speciesExceptions':
        fileName = 'species_exceptions.json';
        break;
      case 'waterbodyExceptions':
        fileName = 'waterbody_exceptions.json';
        break;
      case 'fishSanctuaries':
        fileName = 'fish_sanctuaries.json';
        break;
      case 'baitRestrictions':
        fileName = 'bait_restrictions.json';
        break;
      default:
        fileName = 'Fisheries_Management_Zone_Data.json';
    }
  
    fetch(`${process.env.PUBLIC_URL}/data/${fileName}`)
      .then((response) => response.json())
      .then((data) => {
        const zone = data.zones.find((z) => z.zone_id === zoneId);
        if (zone) {
          let dataToDisplay;
          switch (activeTab) {
            case 'speciesExceptions':
              dataToDisplay = zone.species_exceptions || [];
              break;
            case 'waterbodyExceptions':
              dataToDisplay = zone.waterbody_exceptions || [];
              break;
            case 'fishSanctuaries':
              dataToDisplay = zone.fish_sanctuaries || [];
              break;
            case 'baitRestrictions':
              dataToDisplay = zone.bait_restrictions || [];
              break;
            default:
              dataToDisplay = zone.species_regulations || [];
          }
          setRegulations(dataToDisplay);
        } else {
          console.error(`No data found for Zone ${zoneId}`);
          setRegulations(null);
        }
      })
      .catch((error) => console.error(`Error loading data for Zone ${zoneId}:`, error));
  }, [activeTab]);
  

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        if (!zoneData) {
          console.warn("Zone data not yet loaded.");
          return;
        }

        const { lat, lng } = e.latlng;
        setPinLocation([lat, lng]);

        const userPoint = turf.point([lng, lat]);

        const foundZone = zoneData.features.find((feature) => {
          if (feature.geometry.type === "Polygon") {
            const zonePolygon = turf.polygon(feature.geometry.coordinates);
            return turf.booleanPointInPolygon(userPoint, zonePolygon);
          } else if (feature.geometry.type === "MultiPolygon") {
            return feature.geometry.coordinates.some((polygonCoords) => {
              const zonePolygon = turf.polygon(polygonCoords);
              return turf.booleanPointInPolygon(userPoint, zonePolygon);
            });
          }
          return false;
        });

        if (foundZone) {
          const zoneId = foundZone.properties.FISHERIES_MANAGEMENT_ZONE_ID;
          setUserZone(zoneId);
          loadZoneData(zoneId);
        } else {
          setUserZone(null);
          setRegulations(null);
          alert("This location is not in a defined fishing zone.");
        }
      }
    });
    return null;
  };

  const customIcon = L.icon({
    iconUrl: `${process.env.PUBLIC_URL}/marker-icon.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
  });

  useEffect(() => {
    if (userZone) {
      loadZoneData(userZone);
    }
  }, [activeTab, userZone, loadZoneData]);

  return (
    <div>
      <h2>Fishing Zone Map</h2>
      {userZone && <p>Your Zone: {userZone}</p>}
      <MapContainer center={[50.0, -85.0]} zoom={5} style={{ height: "50vh", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {zoneData && <GeoJSON data={zoneData} />}
        <MapEvents />
        {pinLocation && (
          <Marker position={pinLocation} icon={customIcon} />
        )}
      </MapContainer>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {regulations && <ZoneRegulations regulations={regulations} activeTab={activeTab} />}
    </div>
  );
}

export default MapComponent;
