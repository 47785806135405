import React, { useEffect, useState } from "react";
import "./Tabs.css"; // Ensure your styles are imported

const getSeasonStatus = (season) => {
  const today = new Date();
  if (season?.toLowerCase() === "open all year") return "Open";

  const dateRangeMatch = season?.match(/(\w+ \d{1,2}) to (\w+ \d{1,2})/);
  if (dateRangeMatch) {
    const [, start, end] = dateRangeMatch;
    const startDate = new Date(`${start} ${today.getFullYear()}`);
    let endDate = new Date(`${end} ${today.getFullYear()}`);
    if (endDate < startDate) endDate.setFullYear(endDate.getFullYear() + 1);
    return today >= startDate && today <= endDate ? "Open" : "Closed";
  }
  return "Closed";
};

const ZoneRegulations = ({ regulations = [], activeTab }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedRegulation, setSelectedRegulation] = useState(null);

  // Monitor window resize to detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle row click only for the "Zone-wide Seasons and Limits" tab
  const handleRowClick = (regulation) => {
    if (activeTab === "zoneWide") {
      setSelectedRegulation(regulation); // Open the modal with the clicked row's data
    }
  };

  const handleCloseModal = () => {
    setSelectedRegulation(null); // Close the modal
  };

  return (
    <>
      <div className="table-wrapper"> {/* Wrapper for horizontal scrolling */}
        <table className="custom-regulations-table">
          <thead>
            <tr>
              {activeTab === "zoneWide" && (
                <>
                  <th>Species</th>
                  <th>Season</th>
                  {isMobile ? null : (
                    <>
                      <th>Spot Daily Limit</th>
                      <th>Spot Size Limit</th>
                      <th>Conservation Daily Limit</th>
                      <th>Conservation Size Limit</th>
                    </>
                  )}
                  <th>Status</th>
                </>
              )}
              {activeTab === "speciesExceptions" && (
                <>
                  <th>Species</th>
                  <th>Season</th>
                  <th>Spot Daily Limit</th>
                  <th>Spot Size Limit</th>
                  <th>Conservation Daily Limit</th>
                  <th>Conservation Size Limit</th>
                  <th>Status</th>
                  <th>Locations</th>
                </>
              )}
              {activeTab === "waterbodyExceptions" && (
                <>
                  <th>Waterbody</th>
                  <th>Coordinates</th>
                  <th>Township</th>
                  <th>Area Description</th>
                  <th>Restrictions</th>
                </>
              )}
              {activeTab === "fishSanctuaries" && (
                <>
                  <th>Restriction Period</th>
                  <th>Waterbody</th>
                  <th>Coordinates</th>
                  <th>Area Description / Note</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {regulations?.length > 0 ? (
              regulations.map((regulation, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowClick(regulation)}
                  className={`clickable-row ${
                    activeTab === "zoneWide" ? "active" : ""
                  }`}
                  style={{
                    cursor: activeTab === "zoneWide" ? "pointer" : "default",
                  }}
                >
                  {activeTab === "zoneWide" && (
                    <>
                      <td>{regulation.species || "N/A"}</td>
                      <td>{regulation.season || "N/A"}</td>
                      {isMobile ? null : (
                        <>
                          <td>{regulation.spot_daily_limit || "N/A"}</td>
                          <td>{regulation.spot_size_limit || "N/A"}</td>
                          <td>{regulation.con_daily_limit || "N/A"}</td>
                          <td>{regulation.con_size_limit || "N/A"}</td>
                        </>
                      )}
                      <td>
                        <span
                          className={`status ${getSeasonStatus(
                            regulation.season
                          )}`}
                        >
                          {getSeasonStatus(regulation.season)}
                        </span>
                      </td>
                    </>
                  )}
                  {activeTab === "speciesExceptions" && (
                    <>
                      <td>{regulation.species || "N/A"}</td>
                      <td>{regulation.season || "N/A"}</td>
                      <td>{regulation.spot_daily_limit || "N/A"}</td>
                      <td>{regulation.spot_size_limit || "N/A"}</td>
                      <td>{regulation.con_daily_limit || "N/A"}</td>
                      <td>{regulation.con_size_limit || "N/A"}</td>
                      <td>
                        <span
                          className={`status ${getSeasonStatus(
                            regulation.season
                          )}`}
                        >
                          {getSeasonStatus(regulation.season)}
                        </span>
                      </td>
                      <td>
                        {regulation.locations?.length > 0 ? (
                          <ul>
                            {regulation.locations.map((loc, locIndex) => (
                              <li key={locIndex}>
                                <strong>{loc.waterbody}</strong>,{" "}
                                {loc.coordinates || ""} - {loc.township || ""}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </>
                  )}
                  {activeTab === "waterbodyExceptions" && (
                    <>
                      <td>{regulation.waterbody || "N/A"}</td>
                      <td>{regulation.coordinates || "N/A"}</td>
                      <td>{regulation.townships?.join(", ") || "N/A"}</td>
                      <td>{regulation.area_description || "N/A"}</td>
                      <td>
                        {regulation.restrictions?.length > 0 ? (
                          <ul>
                            {regulation.restrictions.map(
                              (restriction, resIndex) => (
                                <li key={resIndex}>
                                  <strong>Species:</strong>{" "}
                                  {restriction.species || "N/A"}
                                  <br />
                                  <strong>Limits:</strong>
                                  <ul>
                                  {regulation.locations?.length > 0 ? (
                                  <ul>
                                    {regulation.locations?.map((loc, locIndex) => (
                                      <li key={locIndex}>
                                        <strong>{loc.waterbody || "N/A"}</strong>, {loc.coordinates || "N/A"} - {loc.township || "N/A"}
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  <span>No locations available.</span>
                                )}
                                  </ul>
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </>
                  )}
                  {activeTab === "fishSanctuaries" && (
                    <>
                      <td>{regulation.restriction_period || "N/A"}</td>
                      <td>{regulation.waterbody || "N/A"}</td>
                      <td>{regulation.coordinates || "N/A"}</td>
                      <td>
                        {regulation.area_description || "N/A"}
                      </td>
                    </>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No regulations available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal Implementation */}
      {selectedRegulation && activeTab === "zoneWide" && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={handleCloseModal}>
              &times;
            </button>
            <h2>{selectedRegulation.species || "N/A"}</h2>
            <p>
              <strong>Season:</strong> {selectedRegulation.season || "N/A"}
            </p>
            {!isMobile && (
              <>
                <p>
                  <strong>Spot Daily Limit:</strong>{" "}
                  {selectedRegulation.spot_daily_limit || "N/A"}
                </p>
                <p>
                  <strong>Spot Size Limit:</strong>{" "}
                  {selectedRegulation.spot_size_limit || "N/A"}
                </p>
                <p>
                  <strong>Conservation Daily Limit:</strong>{" "}
                  {selectedRegulation.con_daily_limit || "N/A"}
                </p>
                <p>
                  <strong>Conservation Size Limit:</strong>{" "}
                  {selectedRegulation.con_size_limit || "N/A"}
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ZoneRegulations;
