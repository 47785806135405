import React from 'react';
import './Tabs.css'; // Ensure your styles are imported

const Tabs = ({ activeTab, setActiveTab }) => {
  const tabs = [
    { id: 'zoneWide', label: 'Zone-wide Seasons and Limits' },
    { id: 'speciesExceptions', label: 'Species Exceptions' },
    { id: 'waterbodyExceptions', label: 'Waterbody Exceptions' },
    { id: 'fishSanctuaries', label: 'Fish Sanctuaries' },
    { id: 'baitRestrictions', label: 'Bait Restrictions' },
  ];

  return (
    <div className="tabs-container"> {/* Updated class for scrollable container */}
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`tab ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
