import React from 'react';
import MapComponent from './components/MapComponent';
import './App.css';
//import './index.css';



function App() {
  return (
    <div>
      <h1>Fishing Zone Map</h1>
      <MapComponent />
    </div>
  );
}

export default App;




//import React from 'react';
//import Dashboard from './components/Dashboard';
//import './App.css';

//function App() {
//  return (
//    <div>
//      <Dashboard />
//    </div>
//  );
//}

//export default App;
